import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import '../App.css';
import { FaTrash, FaInfoCircle } from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';

function BotSelection({ bots, selectedBot, onSelectBot }) {
  return (
    <div className="input-group">
      <select 
        value={selectedBot?.botName} 
        onChange={e => onSelectBot(bots.find(bot => bot.botName === e.target.value))}
        className="input-field"
      >
        <option value="" disabled>Select Bot</option>
        {bots.map(bot => <option key={bot.id} value={bot.botName}>{bot.botName}</option>)}
      </select>
      <label className="floating-label">Bot</label>
    </div>
  );
}

const TelegramForm = () => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [channelId, setChannelId] = useState('');
  const [bots, setBots] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [message, setMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchBots = async () => {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const collections = ['automaton-bybit', 'toreedo-bybit', 'toreedo-phemex', 'automaton-bitget', 'automaton-okx', 'DEX-APOLLOX'];
      const results = [];

      for (let coll of collections) {
        const collRef = collection(db, coll);
        const collQuery = query(collRef, where('userId', '==', userId));
        const collSnapshot = await getDocs(collQuery);

        collSnapshot.docs.forEach(doc => {
          results.push({
            id: doc.id,
            ...doc.data(),
          });
        });
      }

      setBots(results);

      if (results.length > 0) {
        setSelectedBot(results[0]);
      }
    };

    const fetchTokens = async () => {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const tokenCollection = collection(db, 'telegram-tokens');
      const tokenQuery = query(tokenCollection, where('userId', '==', userId));
      const tokenSnapshot = await getDocs(tokenQuery);

      const tokenResults = tokenSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setTokens(tokenResults);
    };

    fetchBots();
    fetchTokens();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (selectedBot) {
      const botData = {
        botToken: token,
        userId: auth.currentUser?.uid,
        botName: selectedBot.botName,
        channelId: channelId,
      };
    
      const botCollection = collection(db, 'telegram-tokens');
      const botQuery = query(botCollection, where('botName', '==', selectedBot.botName), where('userId', '==', auth.currentUser?.uid));
      
      const botSnapshot = await getDocs(botQuery);
      if (!botSnapshot.empty) {
        setMessage('A token for this bot already exists.');
        setLoading(false);
        return;
      }
    
      try {
        const docRef = await addDoc(botCollection, botData);
        setMessage('New bot token added.');
        setToken('');
        setChannelId('');
        setTokens(prev => [...prev, { id: docRef.id, ...botData }]);  // Add new token to state
      } catch (error) {
        setMessage('Error adding new bot token: ' + error);
      }
    } else {
      setMessage('No bot selected.');
    }
    setLoading(false);
  };

  const handleDelete = async (tokenId) => {
    const confirm = window.confirm('Do you want to delete this bot token?');
    if (!confirm) return;

    setLoading(true);

    try {
      await deleteDoc(doc(db, 'telegram-tokens', tokenId));
      setTokens(tokens.filter(token => token.id !== tokenId));  // Remove token from state
      setMessage("Bot token deleted.");
    } catch (error) {
      setMessage('Error deleting bot token: ' + error);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="form-container">
        <button className="info-button" onClick={() => setShowModal(true)}>
          <FaInfoCircle />
        </button>
        <form className="form" onSubmit={handleSubmit}>
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <BotSelection bots={bots} selectedBot={selectedBot} onSelectBot={setSelectedBot} />
          <div className="input-group">
            <input 
              type="text" 
              name="telegramToken" 
              id="telegramToken" 
              className="input-field" 
              value={token} 
              onChange={(e) => setToken(e.target.value)} 
              required 
            />
            <label className="floating-label">Telegram Token</label>
          </div>
          <div className="input-group">
            <input 
              type="text" 
              name="telegramUserId" 
              id="telegramUserId" 
              className="input-field" 
              value={channelId} 
              onChange={(e) => setChannelId(e.target.value)} 
              required 
            />
            <label className="floating-label">Telegram User ID</label>
          </div>
          <div className="button-group">
            <button type="submit" className="button">Submit</button>
          </div>
        </form>
        {message && <div className={message.includes('Error') ? 'error-message' : 'success-message'}>{message}</div>}
        <div className="tokens-list">
          {tokens.map(token => (
            <div className="bot-card" key={token.id}>
              <div className="bot-info">
                <div className="bot-name">{token.botName}</div>
                <div className="exchange">{token.channelId}</div>
              </div>
              <button className="delete-button" onClick={() => handleDelete(token.id)}>
                <FaTrash /> 
              </button>
            </div>
          ))}
        </div>
      </div>
      
      {showModal && (
        <div className="modal">
          <div className="overlay" onClick={() => setShowModal(false)}></div>
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowModal(false)}>&times;</button>
            <div className="modal-content-body">
              <h2>Welcome to the Telegram Bot Setup Guide</h2>
              <p>
                This guide is designed to help you create, manage, and secure a Telegram bot. Follow these step-by-step instructions to ensure a smooth setup and operation of your bot.
              </p>
              <p>
                <strong>Step 1: Create a Telegram Bot</strong><br />
                Open Telegram and start a new chat with @BotFather.<br />
                Send the command /newbot to begin the bot creation process.<br />
                Follow the prompts provided by @BotFather. Once your bot is created, you will receive a unique token. This token is crucial for your bot's operation, so keep it secure.
              </p>
              <p>
                <strong>Step 2: Obtain Your User/Channel ID</strong><br />
                To get your User ID or Channel ID, start a conversation with @chat_id_echo_bot on Telegram.<br />
                Forward any message from the chat or channel where you wish the bot to function.<br />
                @chat_id_echo_bot will respond with the ID you need.
              </p>
              <p>
                <strong>Step 3: Fill in the Form</strong><br />
                Navigate to the specified webpage with the bot configuration form.<br />
                Bot Selection: Choose your newly created bot from a dropdown list.<br />
                Telegram Token: Input the token you received from @BotFather.<br />
                Telegram User ID: Enter the ID you got from @chat_id_echo_bot.
              </p>
              <p>
                <strong>Step 4: Submit the Form</strong><br />
                After filling out all required fields, click the 'Submit' button.<br />
                A confirmation message will appear if the information is correct and your bot is registered successfully.
              </p>
              <p>
                <strong>Step 5: Invite the Bot to the Channel</strong><br />
                If you provided a Channel ID in the form, invite your bot to the channel.<br />
                Set the bot’s role as an administrator to enable it to perform necessary tasks.
              </p>
              <p>
                <strong>Step 6: Delete a Bot Token</strong><br />
                If you need to delete your bot's token for security reasons, locate the 'Delete' button on the form.<br />
                Click on it and confirm the deletion to remove the token from the database.
              </p>
              <p>
                <strong>Security and Best Practices</strong><br />
                Never share your Bot Token or User/Channel ID publicly to maintain security.<br />
                Regularly review bot permissions and access settings to ensure it remains secure.
              </p>
              <p>
                <strong>Managing Multiple Telegram Tokens</strong><br />
                Each token is linked to a specific API key account or bot.<br />
                If multiple alerts or functions are needed, generate and manage separate tokens.<br />
                Assign tokens based on whether they should control individual or multiple channels, depending on your needs.
              </p>
              <p>
                <strong>FAQ Section</strong><br />
                Q: What if my bot doesn't respond?<br />
                A: Check the bot token and user/channel IDs for accuracy. Ensure the bot has the necessary permissions in the channel.<br />
                Q: Can I update the bot's settings?<br />
                A: Yes, use @BotFather to modify settings like the bot's name, description, and commands.
              </p>
              <p>
                <strong>Conclusion</strong><br />
                Thank you for following this guide. Should you have any further questions or encounter issues, feel free to reach out for assistance. Your feedback is appreciated to help us improve this guide.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TelegramForm;

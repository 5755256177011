import React, { useState } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import "../App.css";
import googleLogo from './google.jpg'; // Ensure the path to the Google logo is correct

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setErrorMessage("Please enter your email for password reset.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent. Check your inbox.");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/api");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const switchMode = () => setResetPasswordMode(!resetPasswordMode);

  return (
    <div className="form-container">
      <div className="sign-up-page">
        <div className="logo-section">
          {/* Optionally place a logo here */}
        </div>
        <div className="form-wrapper">
          <h1>{resetPasswordMode ? 'Reset Password' : 'Login'}</h1>
          <form onSubmit={resetPasswordMode ? handlePasswordReset : handleSubmit} className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-text"
              required
            />
            {resetPasswordMode ? (
              <button type="submit" className="button">Send Reset Email</button>
            ) : (
              <>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-text"
                  required
                />
                <button type="submit" className="button">Login</button>
              </>
            )}
          </form>
          {!resetPasswordMode && (
            <>
              <button onClick={switchMode} className="button forgot-password">Forgot Password?</button>
              <button onClick={handleGoogleLogin} className="button google-signup-btn">
                <img src={googleLogo} alt="Google logo" style={{ width: '20px', marginRight: '10px' }} />
                Google Login
              </button>
            </>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button onClick={() => navigate('/signup')} className="button">Don't have an account? Sign Up</button>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React from 'react';
import { AiOutlineRobot } from 'react-icons/ai'; // Using an icon from react-icons
import './AiButton.css';

const AiButton = () => {

  const openLink = () => {
    window.open('https://chatgpt.com/g/g-nqlp3fqAd-indicatorbot', '_blank');
  };

  return (
    <div className="ai-button">
      <button onClick={openLink}>
        <AiOutlineRobot size={20} /> {/* Adjust the size as needed */}
      </button>
    </div>
  );
};

export default AiButton;

import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import googleLogo from './google.jpg'; // Ensure the path to the Google logo is correct

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (password !== passwordConfirm) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/api");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="form-container">
      <div className="sign-up-page">
        <div className="logo-section">
          {/* Optionally place a logo here */}
        </div>
        <div className="forms-container">
          <div className="form-wrapper">
            <h1>Sign Up</h1>
            <form onSubmit={handleSignUp} className="input-group">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className="input-text"
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                className="input-text"
                required
              />
              <input
                type="password"
                placeholder="Confirm Password"
                value={passwordConfirm}
                onChange={(event) => setPasswordConfirm(event.target.value)}
                className="input-text"
                required
              />
              <button type="submit" className="button">Sign Up</button>
            </form>
            <button onClick={handleGoogleSignUp} className="button google-signup-btn">
              <img src={googleLogo} alt="Google logo" style={{ width: '20px', marginRight: '10px' }} />
              Google Sign Up
            </button>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button onClick={() => navigate('/login')} className="button">Already have an account? Login</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;

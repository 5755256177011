import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Api from './components/Api';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Navbar from './components/Navbar';
import { AuthProvider } from './AuthContext';
import Telegram from './components/Telegram';
import LinksPage from './LinksPage';
import Account from './components/Account';
import PrivateRoute from './PrivateRoute';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/api" element={<PrivateRoute element={Api} />} />
          <Route path="/telegram" element={<PrivateRoute element={Telegram} />} />
          <Route path="/linkspage" element={<PrivateRoute element={LinksPage} />} />
          <Route path="/account" element={<PrivateRoute element={Account} />} />

        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
